import React from 'react'



class WhyChoose extends React.Component {


  render() {

    return (
      <section className="whatis pt-100 pb-0 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              
              <h2 className="heading-h2"><span className="heading-h3 bluecolor">Why Choose </span>
                Coinsclone For Foundation Clone Script?</h2>
                <p className="pharagraph head">
              </p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 left-side" >
              <img  width="477px" height="479px" src="https://coinsclone.mo.cloudinary.net/images/foundation-new/why-choose-coinsclone-for-foundation-clone-script.png" alt="Why Choose our Foundation Clone Script" />
            </div>
            <div className="col-lg-7 col-md-12">
              <p className="pharagraph">Coinsclone is a genuine and well-established NFT Marketplace clone script provider in the crypto sector. We have significant years of experience in delivering bug-free crypto-based clone script products with utmost perfection. We offer a high-quality white-label foundation clone script with advanced frameworks at a moderate cost. Our team of seasoned blockchain developers has crafted this clone script after observing and analyzing the foundation’s pros and cons. Whatever issues exist in the existing Foundation platform have been removed from our exclusive Foundation clone script.
              </p>
              <p className="pharagraph mb-0">The script is inbuilt with Avant-grade features and the architecture of this script is based on the powerful Ethereum blockchain. The great thing about purchasing the Foundation clone script from us is that we have a highly experienced team of blockchain developers who have delivered and maintained multiple NFT Marketplace platforms. This gives us a strong understanding of how this market works and what capabilities a typical script requires to perform well & make money.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose