import React from 'react'

import ButtonComponent from '../ButtonComponent/ButtonComponent'

class TopFeatures extends React.Component {

  render() {
    return (
      <section className="topfeaturez pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h4"><span className="bluecolor">Top Features</span> of </span>
                Our Foundation Clone Script</h2>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h3 className="heading-h4">Different Asset Categories</h3>
                <p className="pharagraph">Every user has their own likes and dislikes. To meet their expectations, our foundation clone script comes with the advanced asset categorizing features where users can choose their desired asset for trading.
                </p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/foundation-new/different-asset-categories.png" alt="Different Asset Categories" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order1">
              <img  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/foundation-new/nft-marketplace.png" alt="NFT Marketplace" />
            </div>
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-right" >
                <h3 className="heading-h4">NFT Marketplace</h3>
                <p className="pharagraph">Our Foundation clone script is an Ethereum based platform that enables users to mint, bid and buy the ownership rights to digital works of art via Non- Fungible Tokens (NFTs).
                </p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h3 className="heading-h4">Performance Monitoring</h3>
                <p className="pharagraph">Using our Foundation clone script the site owner/admin can be able to track and monitor the user performances from step one.</p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6  order1">
              <img  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/foundation-new/performance-monitoring.png" alt="Performance Monitoring" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/foundation-new/limitless-asset-listing.png" alt="Limitless Asset Listing" />
            </div>
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-right" >
                <h3 className="heading-h4">Limitless Asset Listing</h3>
                <p className="pharagraph">Creators can list their assets in a simple way. Most importantly, our Foundation clone script provides the facility for countless listings!
                </p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>

          </div>
          <div className="row table-content orderflex mb-0">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h3 className="heading-h4">Analytics Statistics</h3>
                <p className="pharagraph">Users can be able to stay updated with their trade statistics and also analyze them to perform better in the future using our Foundation clone script.
                </p>
                <div className="text-left ">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6  order1">
              <img  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/foundation-new/analytics-statistics.png" alt="Analytics Statistics" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TopFeatures