import React from 'react'



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis py-0 mb-0">
        <div className='gray-bg'>
        <div className="container">
        <div className="shadoww">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              
              <h2 className="heading-h2"><span className="heading-h3 bluecolor">What is </span>
                White Label Foundation Clone Script?</h2>
            </div>
          </div>

          <div className="text-center">
              <p className="pharagraph">The foundation clone script is the ready-to-deploy NFT Marketplace software that works similarly to the Foundation platform. This clone script comprises all the vital technical features and security modules that are necessary to build a stunning NFT Marketplace within a week that looks 100% similar to the foundation. By creating a blockchain-enabled NFT Marketplace, users can convert digital, and even real-world assets into non-fungible tokens. This means any assets can be converted into non-fungible tokens and added to crypto wallets.
              </p>
              <p className="pharagraph mb-0">Our premium foundation clone script runs on the Ethereum blockchain which is multi-tested and it is ready to be used. When you purchase it, you get the complete source code to launch your own NFT Marketplace like the Foundation. It has many customizable options which is a major highlight in using the white label foundation clone script. So, you can modify the functions and other aspects of the platform according to your individual business needs.
              </p>
            </div>
            </div>
        </div>
        </div>
      </section>
    )
  }
}

export default Whatis