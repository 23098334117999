import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'

const OurAndroid = () =>{

    return (
      <section className="android pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-left leftpad">
            
            <h2 className="heading-h2"><span className="heading-h3">Our <span className="bluecolor">Android & iOS App </span></span>
              For Foundation Clone App Development</h2>
            <p className="pharagraph head">Launch a user-friendly and eye-catching cryptocurrency trading mobile app like Foundation
            </p>
          </div>

          <div className="row table-content orderflex mobilewhitebox">
            <div className="col-md-12 col-lg-7 order2 mb-0">
              <p className="pharagraph" >With the help of our exclusive Foundation clone script, you can launch a superfine NFT Marketplace mobile app like Foundation. You can acquire the top-rated Foundation clone app development services from Coinsclone and build a fully fledged NFT trading mobile application that works 100% similar to the Foundation app. Our foundation clone app is devised on the Ethereum blockchain, so creators in the platform can mint, bid, buy, and sell all ethereum-based NFTs in a stress-free way.
              </p>
              <p className="pharagraph" >Similar to the foundation like the NFT Marketplace website platform, the clone app will also be able to hold auctions for digital artists. Digital artists can mint their artworks into non-fungible tokens and then sell them to interested parties. Coinsclone offers a premium Foundation clone app development services as a complete package to all startups at a budget-friendly cost. The clone app we offer is open for personalization. So you can alter the core functions, theme, visuals, and other vital functionalities of the app as per your preferences. Our foundation clone mobile app is fully developed, designed, multi-tested, and ready for deployment.
              </p>
              <div className='mobile-hide'>
              <ButtonComponent />
              </div>
            </div>
            <div className="col-md-12 col-lg-5 mobileappicon text-center order1">
              <img width="530px" height="650px" src="https://coinsclone.mo.cloudinary.net/images/foundation/foundation-clone-app-development.png" alt="foundation clone app development" />
            </div>
          </div>

          <div className="inner-container">
            <div className="platform">
              <div className="t-black plat-dev">Platforms Developed</div>
              <div className="playstoreicon">
                <div className="play">
                  <img src="https://coinsclone.mo.cloudinary.net/images/android.svg" alt="Android" />
                  <span>Android</span> </div>
                <div className="play">
                  <img src="https://coinsclone.mo.cloudinary.net/images/apple.svg" alt="Apple" />
                  <span>ios</span> </div>
              </div>
            </div>
          </div>

          <div className='mobile-on'>
              <ButtonComponent />
              </div>
        </div>
      </section>
    )
}

export default OurAndroid