import React from 'react'


const SecurityofOur = () => {

  return ( 
    <section className="security pt-100 mb-0 pb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12  text-center">

            <h2 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Security Features</span> of </span>
              Our Foundation Clone Software</h2>
            <p className="pharagraph head">Being built on one of the most secure blockchain architectures, our foundation clone script delivers unmatched security for your platform.</p>
          </div>
        </div>
        <div className="binanceflexbox mt-0 version2">
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="30.261" height="30.277" viewBox="0 0 30.261 30.277">
                  <g id="Multilayered_authentication" data-name="Multilayered authentication" transform="translate(0 0.001)">
                    <path id="Path_2327" data-name="Path 2327" d="M136.223,234.168H117.01a1.778,1.778,0,0,0-.189-.063,3.351,3.351,0,0,1-2.372-1.726A8.167,8.167,0,0,1,114,231.15V206.918a1.356,1.356,0,0,0,.055-.165,3.422,3.422,0,0,1,2.372-2.687c.189-.063.394-.11.591-.165h16.848c-.008.016-.008.039-.016.055a5.7,5.7,0,0,0-.938,2.884.374.374,0,0,1-.165.244,4.722,4.722,0,0,0-2.356,4.255c0,1.355-.008,2.719,0,4.074a5.312,5.312,0,0,0,.071.938,4.7,4.7,0,0,0,4.7,3.932c1.237.008,2.482,0,3.72,0,.1,0,.213.008.323.016.008.087.016.142.016.2,0,3.4.008,6.816-.008,10.221a3.257,3.257,0,0,1-1.513,2.821,7.682,7.682,0,0,1-1.477.627Zm-10.237-7.258v-.449c0-.418.079-.5.5-.5.552,0,1.1.008,1.655,0a.943.943,0,1,0,.016-1.883c-.63-.016-1.261-.024-1.891,0A2.178,2.178,0,0,0,124.1,226.3c-.008,1.1-.024,2.206.008,3.31a2.135,2.135,0,0,0,2,2.01c.709.031,1.418.016,2.128.008a.9.9,0,0,0,.843-.63.946.946,0,0,0-.93-1.253c-.591-.016-1.182-.008-1.773,0-.2,0-.363-.047-.386-.276-.016-.213,0-.426,0-.67h2.21a.9.9,0,0,0,.867-.6.945.945,0,0,0-.938-1.284c-.7-.015-1.4,0-2.143,0Zm-6.312-1.182c0-.292.008-.5,0-.7a.952.952,0,0,0-.93-.954.939.939,0,0,0-.954.961c-.008,1.883-.008,3.759,0,5.642a.942.942,0,1,0,1.883-.008c.008-.2,0-.4,0-.67.418.465.772.875,1.135,1.277a.949.949,0,1,0,1.4-1.269c-.252-.292-.512-.575-.764-.859l-1.135-1.277c.646-.725,1.277-1.434,1.9-2.143a.951.951,0,1,0-1.426-1.245c-.345.387-.7.788-1.109,1.245Zm13.239.181c-.268-.481-.5-.906-.741-1.332a.962.962,0,0,0-.961-.5.947.947,0,0,0-.686,1.418c.426.8.883,1.576,1.308,2.372a1.1,1.1,0,0,1,.126.5c.016.749,0,1.5.008,2.246a.948.948,0,1,0,1.891-.008c0-.725-.008-1.458.008-2.183a1.361,1.361,0,0,1,.15-.583c.41-.772.843-1.529,1.269-2.3a.942.942,0,0,0-.339-1.348.96.96,0,0,0-1.324.449c-.229.4-.449.813-.709,1.27Z" transform="translate(-114 -203.892)" fill="gray" />
                    <path id="Path_2328" data-name="Path 2328" d="M365.348,215.81a4.141,4.141,0,0,1-.15.441,2.137,2.137,0,0,1-2.049,1.426c-2.317.024-4.626.024-6.943,0A2.188,2.188,0,0,1,354,215.392v-4.169a2.215,2.215,0,0,1,2.293-2.38c.071,0,.134-.016.236-.024,0-.615-.008-1.221,0-1.836a3.024,3.024,0,0,1,1.537-2.719,7.549,7.549,0,0,1,1.2-.465h.827a.964.964,0,0,0,.134.055,3.061,3.061,0,0,1,2.537,2.537,13.937,13.937,0,0,1,.063,1.907c.008.173,0,.347,0,.544.15,0,.26-.008.362,0a2.176,2.176,0,0,1,2,1.4,4.748,4.748,0,0,1,.158.473Zm-4.429-6.982c0-.7.032-1.379-.008-2.057a1.244,1.244,0,0,0-2.459-.024c-.047.686-.008,1.371-.008,2.073C359.256,208.828,360.076,208.828,360.919,208.828Z" transform="translate(-335.087 -203.8)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h3 className="card-title">Multistep authentication</h3> </div>
            </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="33.022" height="33.031" viewBox="0 0 33.022 33.031">
                  <g id="Security_features_of_native" data-name="Security features of native" transform="translate(-0.004)">
                    <path id="Path_8930" data-name="Path 8930" d="M846.792,458.8c-.752-.36-1.53-.677-2.25-1.1a16.156,16.156,0,0,1-8.2-12.357,16.491,16.491,0,0,1-.121-2.057c-.016-1.838-.005-3.676-.005-5.515v-.41a12.521,12.521,0,0,0,10.641-4.271,13.034,13.034,0,0,0,4.816,3.431,13.708,13.708,0,0,0,5.83.848v5.983a16.4,16.4,0,0,1-10.322,15.321c-.09.036-.17.085-.256.128Zm-6.744-17.722c.039,1.276-.023,2.543.132,3.78a12.227,12.227,0,0,0,6.47,9.6.462.462,0,0,0,.4.007,21.949,21.949,0,0,0,2.44-1.724c3.481-3.129,4.474-7.162,4.088-11.67a1.357,1.357,0,0,0-.16-.047,16.272,16.272,0,0,1-6.382-2.622c-.167-.114-.263-.047-.4.03-.829.472-1.643.976-2.5,1.4a15.747,15.747,0,0,1-4.088,1.241Z" transform="translate(-830.341 -425.77)" fill="gray" />
                    <path id="Path_8931" data-name="Path 8931" d="M1012.81,399.232a2.586,2.586,0,0,0-.27.208q-1.383,1.376-2.759,2.759a2.129,2.129,0,0,0-.2.258l-1.383-1.385.87-.833-.04-.1h-.321q-2.951,0-5.9-.006a.5.5,0,0,1-.371-.152c-.913-1.493-1.809-3-2.726-4.526l1.656-1,.482.8c.547.912,1.091,1.826,1.649,2.73a.45.45,0,0,0,.309.2c1.71.013,3.42.008,5.13.007a.427.427,0,0,0,.09-.022l-.872-.836,1.416-1.4c.02.019.1.1.185.18l2.783,2.781a2.757,2.757,0,0,0,.27.21Z" transform="translate(-979.784 -390.459)" fill="gray" />
                    <path id="Path_8932" data-name="Path 8932" d="M768,399.1a2.1,2.1,0,0,0,.245-.187q1.391-1.412,2.777-2.829c.067-.068.139-.131.18-.174l1.365,1.363-.969.9c.2.015.288.028.377.028q2.467,0,4.932.006a.429.429,0,0,0,.431-.242c.687-1.165,1.386-2.321,2.1-3.51l1.649.99c-.045.09-.08.159-.121.229-.844,1.409-1.692,2.815-2.529,4.23a.43.43,0,0,1-.43.242c-1.98-.009-3.953,0-5.931,0h-.41l.945.895-1.406,1.392c-.043-.041-.127-.116-.206-.2l-2.777-2.829a1.693,1.693,0,0,0-.222-.164Z" transform="translate(-767.996 -390.454)" fill="gray" />
                    <path id="Path_8933" data-name="Path 8933" d="M914.526,348l1.626,1.012-1.355,2.255-1.657-.99c.187-.312.37-.61.54-.913.262-.45.517-.907.777-1.361Z" transform="translate(-900.653 -348)" fill="gray" />
                    <path id="Path_8934" data-name="Path 8934" d="M973.9,348c.133.265.258.535.4.794.27.495.56.99.85,1.5l-1.678.958-1.3-2.281,1.6-.967Z" transform="translate(-954.611 -348)" fill="gray" />
                    <path id="Path_8935" data-name="Path 8935" d="M912.931,522.974a10.69,10.69,0,0,1-4.611,9.568.332.332,0,0,1-.45.008,10.36,10.36,0,0,1-4.488-7.193c-.118-.771-.122-1.56-.18-2.355.345-.1.638-.193.931-.283a16.689,16.689,0,0,0,3.672-1.62.463.463,0,0,1,.55-.011,17.387,17.387,0,0,0,4.576,1.885Z" transform="translate(-891.571 -506.121)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h3 className="card-title">Security features of native</h3>
              </div>
            </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg id="Support_for_SSL_protocols" data-name="Support for SSL protocols" xmlns="http://www.w3.org/2000/svg" width="30.425" height="27.108" viewBox="0 0 30.425 27.108">
                  <path id="Path_2332" data-name="Path 2332" d="M141.219,235.6H155.2c.853,0,1.225.38,1.225,1.242v24.624c0,.862-.372,1.242-1.225,1.242H127.217c-.837,0-1.217-.38-1.217-1.225V236.834c0-.853.38-1.225,1.242-1.225Q134.226,235.6,141.219,235.6Zm-13.182,6.946h26.331v-4.884H128.036Zm6.5,15.151v-.042c.262,0,.532.008.794,0a2.3,2.3,0,0,0,2.214-2.121,12.077,12.077,0,0,0-.008-1.834,2.3,2.3,0,0,0-2.248-2.079c-.431-.008-.862,0-1.3-.008-.33,0-.431-.11-.439-.439-.008-.38,0-.76,0-1.141a.355.355,0,0,1,.406-.406h1.073c.414,0,.456.042.524.456a1.011,1.011,0,0,0,1.065.87,1.035,1.035,0,0,0,.946-1.048,2.325,2.325,0,0,0-2.138-2.3,14.956,14.956,0,0,0-1.741,0,2.224,2.224,0,0,0-2.1,1.935,9.5,9.5,0,0,0-.008,2.079,2.289,2.289,0,0,0,2.29,2q.634.013,1.268,0a.355.355,0,0,1,.414.4v1.2a.345.345,0,0,1-.389.389c-.38,0-.761-.008-1.141,0a.4.4,0,0,1-.473-.414,1.007,1.007,0,0,0-1.056-.921,1.026,1.026,0,0,0-.955,1.1,2.318,2.318,0,0,0,1.758,2.18,6.235,6.235,0,0,0,1.242.147Zm7.1,0v-.042c.262,0,.532.008.794,0a2.288,2.288,0,0,0,2.214-2.113,12.073,12.073,0,0,0-.008-1.834,2.31,2.31,0,0,0-2.248-2.087c-.439-.008-.887-.008-1.327,0a.365.365,0,0,1-.414-.406V250.04a.361.361,0,0,1,.406-.414h1.073c.423,0,.465.042.532.456a1.01,1.01,0,0,0,2.011-.161,2.325,2.325,0,0,0-2.138-2.3,14.953,14.953,0,0,0-1.741,0,2.24,2.24,0,0,0-2.113,1.935,9.3,9.3,0,0,0,0,2.113,2.279,2.279,0,0,0,2.248,1.977c.431.008.862.008,1.3,0a.361.361,0,0,1,.423.4c.008.4,0,.8,0,1.2,0,.279-.144.4-.423.4q-.57-.013-1.141,0c-.287.008-.414-.127-.439-.414a1.012,1.012,0,0,0-1.056-.921,1.031,1.031,0,0,0-.963,1.09,2.31,2.31,0,0,0,1.749,2.18,8.82,8.82,0,0,0,1.257.119Zm6.109-2.053v-6.971a.993.993,0,0,0-1.073-1.056,1.012,1.012,0,0,0-.946,1.1v5c0,.963-.008,1.918,0,2.882a.976.976,0,0,0,.946,1.065q1.648.025,3.3,0a.957.957,0,0,0,.887-.693,1,1,0,0,0-1.022-1.318c-.693-.026-1.369-.009-2.1-.009Z" transform="translate(-126 -235.6)" fill="gray" />
                  <path id="Path_2333" data-name="Path 2333" d="M175.03,278.928a1.014,1.014,0,1,1,.927-.644A1.015,1.015,0,0,1,175.03,278.928Z" transform="translate(-169.943 -273.41)" fill="gray" />
                  <path id="Path_2334" data-name="Path 2334" d="M223.021,279.018a1.01,1.01,0,1,1,0-2.02,1.026,1.026,0,0,1,1.006,1.014,1.013,1.013,0,0,1-1.006,1.006Z" transform="translate(-213.887 -273.5)" fill="gray" />
                  <path id="Path_2335" data-name="Path 2335" d="M268.5,279.018a1.006,1.006,0,1,1,.934-.617A1,1,0,0,1,268.5,279.018Z" transform="translate(-255.543 -273.5)" fill="gray" />
                </svg>
              </div>
              <div className="card-body">
                <h3 className="card-title">Support for SSL protocols</h3>
              </div></div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="27.04" height="32.453" viewBox="0 0 27.04 32.453">
                  <g id="Inbuilt_SSL_Protocol" data-name="Inbuilt SSL Protocol" transform="translate(0 0.001)">
                    <path id="Path_9276" data-name="Path 9276" d="M96.75,378.5c.01-.126.029-.251.029-.377v-6.152h20.489V378.5Zm9.379-4.138H99.018v1.879H105.2a6.908,6.908,0,0,0,.729-.01.287.287,0,0,0,.192-.192C106.137,375.487,106.129,374.938,106.129,374.362Zm8.778,0h-1.875v1.853h1.878Zm-6.158-.011v1.864h1.86v-1.861Z" transform="translate(-96.75 -346.048)" fill="gray" />
                    <path id="Path_9277" data-name="Path 9277" d="M97.354,277.708V271.12H117.8v6.588Zm2.218-4.209v1.867h7.092V273.5Zm11.589,1.872V273.5H109.3v1.866Zm2.418,0h1.874V273.5h-1.874Z" transform="translate(-97.303 -253.734)" fill="gray" />
                    <path id="Path_9278" data-name="Path 9278" d="M284.522,71.263V68.691a.337.337,0,0,1,.228-.359c1.739-.82,3.473-1.653,5.214-2.471a.512.512,0,0,1,.4,0q2.541,1.335,5.065,2.7a.458.458,0,0,1,.212.328c.012,1.5.021,3.006,0,4.5a7.218,7.218,0,0,1-2.165,4.87,12.547,12.547,0,0,1-3.091,2.411.422.422,0,0,1-.335.044,11.559,11.559,0,0,1-4.5-4.007,6.573,6.573,0,0,1-1.024-3.579Q284.523,72.2,284.522,71.263Zm4.9,1.882L288.2,71.861l-1.3,1.3,2.593,2.6,3.992-3.992-1.342-1.342Z" transform="translate(-268.614 -65.821)" fill="#b0b0b0" />
                    <path id="Path_9279" data-name="Path 9279" d="M111.5,170.169a11,11,0,0,0,4.51,6.59H97.441v-6.59Zm-4.778,4.273v-1.869H99.657v1.869Zm2.651,0h1.863v-1.861h-1.863Z" transform="translate(-97.383 -161.333)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h3 className="card-title">Inbuilt SSL Protocol
                </h3>
              </div> </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="28.772" height="28.772" viewBox="0 0 28.772 28.772">
                  <g id="End-to-end_encryption" data-name="End-to-end encryption" transform="translate(-600.939 -802.505)">
                    <path id="Path_17928" data-name="Path 17928" d="M13.759,171.329H.617A1.29,1.29,0,0,1,0,170.712V151.056H25.368v2.574a.258.258,0,0,1-.073-.005c-.067-.033-.132-.07-.2-.1a6.4,6.4,0,0,0-4.1-.7,6.727,6.727,0,0,0-5.717,6.78v1.7c-.048.027-.08.046-.114.062a2.485,2.485,0,0,0-1.57,2.434c0,2.2-.008,4.4.007,6.6a5.573,5.573,0,0,0,.16.93m-6.14-9.322h3.339a.86.86,0,0,0,.946-.958v-6.51a.86.86,0,0,0-.946-.952H4.337a.86.86,0,0,0-.963.972v6.481a.86.86,0,0,0,.963.974H7.62m-.01,3.369h3.366a.847.847,0,1,0-.009-1.684H4.319a.848.848,0,1,0,.009,1.684H7.61m.028,3.369h3.337a.847.847,0,1,0-.01-1.684H4.318a.848.848,0,1,0,.01,1.684h3.31" transform="translate(600.939 659.948)" fill="gray" />
                    <path id="Path_17929" data-name="Path 17929" d="M0,6.8V.624A1.084,1.084,0,0,1,1.132,0Q12.769.016,24.407.007a.867.867,0,0,1,.982.982v5.53c0,.086-.008.18-.013.283ZM18.651,4.275h2.408a.848.848,0,1,0,.01-1.685H16.218a.847.847,0,1,0-.01,1.684h2.44M4.2,2.591a.842.842,0,1,0,.853.823A.85.85,0,0,0,4.2,2.591m3.44,0a.842.842,0,1,0,.839.837.85.85,0,0,0-.839-.837m3.421,1.683a.842.842,0,1,0-.838-.836.851.851,0,0,0,.838.838" transform="translate(600.939 802.517)" fill="gray" />
                    <path id="Path_17930" data-name="Path 17930" d="M272.4,227.838a1.078,1.078,0,0,1-.626-1.132c.02-2.105.008-4.214.008-6.317,0-.73.305-1.032,1.045-1.032h.64v-.325c0-1.02-.005-2.04,0-3.061a5.054,5.054,0,0,1,10.036-.838,5.82,5.82,0,0,1,.071.946c.009,1.076,0,2.15,0,3.273h.584c.817,0,1.1.281,1.1,1.088,0,2.087-.011,4.174.008,6.261a1.08,1.08,0,0,1-.626,1.132Zm9.484-8.494c0-.071.009-.126.009-.181,0-1.076.005-2.15,0-3.227a3.255,3.255,0,0,0-.086-.694,3.367,3.367,0,0,0-6.652.728c-.007,1.047,0,2.1,0,3.143v.23Zm-2.52,4.241c0-.289.008-.579,0-.868a.84.84,0,1,0-1.68.01q-.014.839,0,1.679a.841.841,0,1,0,1.681-.01c.007-.271,0-.541,0-.812" transform="translate(344.433 603.439)" fill="gray" />
                    <rect id="Rectangle_6670" data-name="Rectangle 6670" width="5.134" height="5.02" transform="translate(606.01 815.238)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h3 className="card-title">End-to-End encryption</h3> </div>
            </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="31.133" height="31.136" viewBox="0 0 31.133 31.136">
                  <g id="Incoming_and_outgoing_API_security" data-name="Incoming and outgoing API security" transform="translate(0 0.003)">
                    <path id="Path_2312" data-name="Path 2312" d="M145.133,216.283v1.1a1.48,1.48,0,0,0-.057.2,5.52,5.52,0,0,1-5.367,4.719q-10.143.024-20.293,0a5.5,5.5,0,0,1-5.238-4.127c-.065-.259-.114-.527-.178-.8v-1.1a.931.931,0,0,0,.049-.138,5.322,5.322,0,0,1,3.13-4.224,1,1,0,0,0,.649-.892,8.193,8.193,0,0,1,6.17-6.794c.413-.1.835-.162,1.249-.235h1.338a1.345,1.345,0,0,0,.17.049,7.9,7.9,0,0,1,4.994,2.432c.146.154.251.122.413.041a6.361,6.361,0,0,1,9.178,4.84.452.452,0,0,0,.332.422,5.22,5.22,0,0,1,2.911,2.74,13.4,13.4,0,0,1,.55,1.762Zm-18.307,2.3a1.2,1.2,0,0,0-.073-.284q-1.35-3.612-2.708-7.216a.937.937,0,0,0-1.735,0q-1.362,3.6-2.708,7.216a.91.91,0,1,0,1.7.641c.13-.324.259-.657.373-1a.267.267,0,0,1,.308-.211q1.2.012,2.4,0a.266.266,0,0,1,.3.219c.122.357.251.705.4,1.054a.917.917,0,0,0,1.013.543.941.941,0,0,0,.73-.959Zm3.648-2.667c.373,0,.705.008,1.038,0a2.738,2.738,0,0,0,2.6-2.538,2.689,2.689,0,0,0-2.222-2.862,17.652,17.652,0,0,0-2.27-.065.911.911,0,0,0-.973.973c-.008.462,0,.932,0,1.4v5.74a.92.92,0,0,0,.5.9.9.9,0,0,0,1.322-.827c.014-.89.006-1.781.006-2.714Zm7.3-.908v-3.551a.917.917,0,1,0-1.824,0v7.078a.919.919,0,1,0,1.824,0c.006-1.174,0-2.349,0-3.525Z" transform="translate(-114 -204)" fill="gray" />
                    <path id="Path_2313" data-name="Path 2313" d="M182.052,463.486a1.109,1.109,0,0,1-.673-1.224c.024-.268.008-.543.008-.835a10.072,10.072,0,0,1-5.805-3.348c-.3.17-.584.341-.876.5a.911.911,0,0,1-1.346-.365c-.284-.478-.559-.965-.835-1.443a.93.93,0,0,1,.357-1.354c.276-.17.689-.276.795-.511s-.122-.6-.178-.916c-.089-.486-.17-.973-.251-1.492h3.665a6.378,6.378,0,0,0,2.943,4.484,6.153,6.153,0,0,0,3.762.957,6.412,6.412,0,0,0,5.9-5.44h3.689c-.154.73-.284,1.435-.47,2.132-.065.243-.016.349.186.446a7.045,7.045,0,0,1,.681.389.9.9,0,0,1,.324,1.224c-.292.543-.608,1.078-.924,1.6a.884.884,0,0,1-1.24.316,5.3,5.3,0,0,1-.47-.276c-.146-.081-.3-.17-.454-.259a10.117,10.117,0,0,1-5.8,3.348c0,.284-.016.559.008.827a1.109,1.109,0,0,1-.673,1.224C183.593,463.486,182.815,463.486,182.052,463.486Z" transform="translate(-167.637 -432.353)" fill="gray" />
                    <path id="Path_2314" data-name="Path 2314" d="M219.022,326.9c.259.7.486,1.3.73,1.938H218.3C218.535,328.213,218.762,327.6,219.022,326.9Z" transform="translate(-209.844 -316.936)" fill="gray" />
                    <path id="Path_2315" data-name="Path 2315" d="M317.4,307.809V306c.349,0,.7-.024,1.03.008a.889.889,0,0,1,.778.9.912.912,0,0,1-.819.9C318.073,307.833,317.749,307.809,317.4,307.809Z" transform="translate(-300.909 -297.723)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h3 className="card-title">Incoming and outgoing API security</h3>
              </div> </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="33.832" height="32.496" viewBox="0 0 33.832 32.496">
                  <g id="Complete_protection_for_Digital_arts" data-name="Complete protection for Digital arts" transform="translate(-64.5 -72.479)">
                    <path id="Path_9328" data-name="Path 9328" d="M64.5,159.173V143.908c5.055,0,10.11-.009,15.165.011a1.482,1.482,0,0,0,1.41-.764c.624-1,1.3-1.961,1.946-2.948a.522.522,0,0,1,.5-.269q7.219.011,14.438,0h.371v18.736a2.968,2.968,0,0,1-3.063,3.067h-6.7c.044-.138.089-.249.114-.364a20.375,20.375,0,0,0,.584-2.833c.11-1.557.107-3.126.061-4.687a2.719,2.719,0,0,0-1.747-2.518,15.324,15.324,0,0,0-4.488-1.159,16.106,16.106,0,0,0-7.609,1.057,2.97,2.97,0,0,0-2,2.865c0,1.123-.05,2.25.014,3.369a29.644,29.644,0,0,0,.379,2.971,10.914,10.914,0,0,0,.373,1.3h-6.6c-.132,0-.265,0-.4-.008a2.98,2.98,0,0,1-2.629-2.128C64.582,159.467,64.542,159.32,64.5,159.173Z" transform="translate(0 -61.516)" fill="gray" />
                    <path id="Path_9329" data-name="Path 9329" d="M64.5,75.057c.05-.179.092-.36.15-.536a2.973,2.973,0,0,1,2.834-2.038c2.247-.008,4.493,0,6.74,0H95.237a2.937,2.937,0,0,1,3.057,2.576,9.83,9.83,0,0,1,.01,1.388h-.375q-7.516,0-15.033-.006a1.267,1.267,0,0,0-1.177.631c-.642,1-1.321,1.976-1.967,2.974a.708.708,0,0,1-.682.375q-7.285-.02-14.57-.009Zm4.962,2.378a.99.99,0,1,0-1-.976A1,1,0,0,0,69.462,77.435Zm4.948-.977a.99.99,0,1,0-1,.977,1,1,0,0,0,1-.977Zm2.959-1a.99.99,0,1,0,1.006.966,1,1,0,0,0-1.006-.969Z" fill="gray" />
                    <path id="Path_9330" data-name="Path 9330" d="M200.754,282.059a12.143,12.143,0,0,1-.393,3.844,10.02,10.02,0,0,1-4.914,6.071,1.2,1.2,0,0,1-1.276,0,9.9,9.9,0,0,1-5.3-8.735c-.041-1.144-.009-2.29-.006-3.435a1.006,1.006,0,0,1,.712-1.035,13.9,13.9,0,0,1,10.4-.022,1.025,1.025,0,0,1,.78,1.119C200.755,280.492,200.754,281.12,200.754,282.059Zm-6.677,3.011c-.251-.28-.455-.541-.693-.767A.985.985,0,0,0,192,285.7q.7.727,1.425,1.424a.934.934,0,0,0,1.318.039c.989-.956,1.959-1.932,2.919-2.918a.89.89,0,0,0,.159-1.018.941.941,0,0,0-.84-.6,1.035,1.035,0,0,0-.85.36c-.663.678-1.331,1.348-2.053,2.082Z" transform="translate(-113.39 -187.182)" fill="#b0b0b0" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h3 className="card-title">Complete protection for Digital arts</h3>
              </div></div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg id="Encryption_and_decryption_of_Digital_arts" data-name="Encryption and decryption of Digital arts" xmlns="http://www.w3.org/2000/svg" width="32.229" height="32.237" viewBox="0 0 32.229 32.237">
                  <path id="Path_5223" data-name="Path 5223" d="M154.466,1a17.188,17.188,0,0,1,1.7.422A6.829,6.829,0,0,1,160.748,7.8c.037,2.31.012,4.614.012,6.924V15.87a8.656,8.656,0,0,0-5.666-3.444v-.34c0-1.372.007-2.751-.007-4.123a1.253,1.253,0,0,0-1.649-1.227,1.268,1.268,0,0,0-.856,1.259c-.007,1.353,0,2.709,0,4.06v.365a8.62,8.62,0,0,0-5.634,3.418c-.012-.12-.025-.2-.032-.264,0-2.53-.012-5.055,0-7.585a6.912,6.912,0,0,1,5.495-6.837c.264-.063.535-.1.8-.151Z" transform="translate(-137.726 -1)" fill="gray" />
                  <path id="Path_5224" data-name="Path 5224" d="M392.026,153.579a3.147,3.147,0,0,1-.793,1.681,6.977,6.977,0,0,1-1.165.831.4.4,0,0,0-.252.4c.012,1.259,0,2.522.007,3.777a.33.33,0,0,1-.187.352q-2.8,1.426-5.59,2.877a1.393,1.393,0,0,1-.145.057,1.583,1.583,0,0,1,.037-.22c.138-.629.271-1.266.422-1.9a.56.56,0,0,1,.252-.34c1.02-.542,2.04-1.07,3.072-1.588a.378.378,0,0,0,.252-.4q-.019-1.322,0-2.644a.322.322,0,0,0-.233-.347,3.131,3.131,0,1,1,4.249-3.513,1.794,1.794,0,0,0,.063.17C392.026,153.032,392.026,153.308,392.026,153.579Z" transform="translate(-359.797 -140.675)" fill="gray" />
                  <path id="Path_5225" data-name="Path 5225" d="M290.781,419.335a12.045,12.045,0,0,1-1.41-.579,2.591,2.591,0,0,1-1.106-1.362.339.339,0,0,0-.39-.258q-1.775.019-3.549,0a.757.757,0,0,1-.467-.187c-.586-.522-1.152-1.064-1.756-1.631.1-.032.157-.057.227-.076.529-.17,1.057-.327,1.58-.516.17-.063.3-.082.409.076a1.214,1.214,0,0,0,1.152.46c.806-.037,1.611-.019,2.428-.007a.332.332,0,0,0,.374-.239,3.135,3.135,0,1,1,3.512,4.264,1.782,1.782,0,0,0-.17.063C291.328,419.335,291.052,419.335,290.781,419.335Z" transform="translate(-264.405 -387.106)" fill="gray" />
                  <path id="Path_5226" data-name="Path 5226" d="M1,316.938a4.473,4.473,0,0,1,.756-1.588,3.137,3.137,0,0,1,5.257.774.434.434,0,0,0,.5.327c.441-.025.882-.007,1.308-.007.246.63.478,1.24.73,1.889-.692,0-1.379.012-2.065-.007a.4.4,0,0,0-.46.3,3.127,3.127,0,0,1-3.047,1.907,3.166,3.166,0,0,1-2.8-2.172l-.17-.529C1,317.524,1,317.234,1,316.938Z" transform="translate(-1 -294.529)" fill="gray" />
                  <path id="Path_5227" data-name="Path 5227" d="M165.219,217.615A6.61,6.61,0,1,1,158.631,211a6.61,6.61,0,0,1,6.588,6.615Zm-5.666,4.072c0-.793.012-1.549-.007-2.3a.467.467,0,0,1,.315-.517,2.825,2.825,0,1,0-2.4.047.329.329,0,0,1,.214.359c-.007.705-.007,1.4,0,2.109,0,.1.007.2.012.3.627,0,1.231,0,1.866,0Z" transform="translate(-142.495 -197.78)" fill="#b0b0b0" />
                  <path id="Path_5228" data-name="Path 5228" d="M23.179,169.225l-.22-.2L21.2,167.267a.291.291,0,0,0-.374-.088,3.138,3.138,0,1,1,1.611-1.636.31.31,0,0,0,.093.4c.4.384.793.774,1.177,1.183a.4.4,0,0,1,.063.34C23.593,168.042,23.393,168.614,23.179,169.225Z" transform="translate(-15.456 -151.09)" fill="gray" />
                  <path id="Path_5229" data-name="Path 5229" d="M243.981,282.021a.941.941,0,1,1-.956-.926.941.941,0,0,1,.956.926Z" transform="translate(-226.922 -263.464)" fill="#b0b0b0" />
                </svg>
              </div>
              <div className="card-body">
                <h3 className="card-title">Encryption and decryption of Digital arts</h3>
              </div> </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="31.111" height="31.136" viewBox="0 0 31.111 31.136">
                  <g id="Secure_transactions" data-name="Secure transactions" transform="translate(-114 -203.7)">
                    <path id="Path_2344" data-name="Path 2344" d="M145.111,206.09v12.517C143,218,140.9,217.392,138.8,216.768a2.921,2.921,0,0,0-1.645-.089c-2.188.616-4.375,1.223-6.554,1.88a2.494,2.494,0,0,0-1.839,2.131,16.338,16.338,0,0,0-.057,2.09c-.008.365,0,.721,0,1.078a.515.515,0,0,1-.122.024c-3.986,0-7.972.008-11.967-.008a2.691,2.691,0,0,1-2.5-1.928l-.122-.437V206.074c.024-.089.049-.17.073-.259a2.659,2.659,0,0,1,1.313-1.742,6.891,6.891,0,0,1,.988-.373h26.372c.057.016.113.04.17.057a2.7,2.7,0,0,1,1.985,1.62C144.99,205.62,145.038,205.863,145.111,206.09Zm-10.022,5.048h3.372a.934.934,0,0,0,.916-.527.906.906,0,0,0-.867-1.3c-1.912-.008-3.824,0-5.736,0H129.7a.914.914,0,1,0-.008,1.823Zm-11.918-.916a2.73,2.73,0,1,0-2.738,2.738A2.74,2.74,0,0,0,123.171,210.222Zm-3.549,6.384c-.316,0-.624-.008-.94,0a.907.907,0,0,0-.016,1.815q.924.024,1.847,0a.907.907,0,0,0-.016-1.815C120.206,216.6,119.914,216.606,119.623,216.606Zm5.436,1.823c.316,0,.624.008.94,0a.908.908,0,0,0-.008-1.815c-.616-.016-1.231-.008-1.847,0a.906.906,0,0,0-.9.915.916.916,0,0,0,.907.907C124.451,218.437,124.751,218.429,125.059,218.429Z" transform="translate(0 0)" fill="gray" />
                    <path id="Path_2345" data-name="Path 2345" d="M324.869,402.226c-.551-.227-1.118-.405-1.645-.681a9.568,9.568,0,0,1-5.4-8.645c-.049-1.4-.008-2.811-.008-4.221a.949.949,0,0,1,.786-1.037c2.042-.583,4.075-1.175,6.117-1.742a1.547,1.547,0,0,1,.77-.008c2.05.567,4.1,1.167,6.149,1.742a1.191,1.191,0,0,1,.762.672v5.282c-.016.089-.04.178-.057.259a9.63,9.63,0,0,1-6.036,8c-.308.138-.64.243-.956.365C325.193,402.226,325.031,402.226,324.869,402.226Zm-.681-7.656-1.126-1.15a.909.909,0,1,0-1.3,1.264c.559.575,1.126,1.134,1.693,1.7a.942.942,0,0,0,1.507-.008c.834-.826,1.661-1.661,2.5-2.487.348-.348.713-.689,1.045-1.053a.906.906,0,0,0-.462-1.523.918.918,0,0,0-.9.316C326.165,392.593,325.2,393.557,324.189,394.57Z" transform="translate(-187.293 -167.39)" fill="gray" />
                    <path id="Path_2346" data-name="Path 2346" d="M183.219,274.823a.912.912,0,1,1,.891-.916A.917.917,0,0,1,183.219,274.823Z" transform="translate(-62.761 -63.685)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h3 className="card-title">Secure transactions</h3>
              </div> </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="27.789" height="24.998" viewBox="0 0 27.789 24.998">
                  <g id="Secure_crypto_wallet_integration" data-name="Secure crypto wallet integration" transform="translate(0 -0.001)">
                    <path id="Path_22378" data-name="Path 22378" d="M0,96.219V79.83a.964.964,0,0,1,1.033-.649q12.861.011,25.725.005a2.579,2.579,0,0,1,.271.005.774.774,0,0,1,.732.652c.041.472.01.949.01,1.444H18.5a1.183,1.183,0,0,0-.816.293c-.592.5-1.2.993-1.8,1.487-.409.337-.819.672-1.224,1-.126-.1-.222-.177-.328-.246a2.45,2.45,0,1,0,1.072,2,.484.484,0,0,1,.2-.432c.948-.768,1.884-1.553,2.834-2.32a.746.746,0,0,1,.433-.155q4.314-.013,8.628-.006c.088,0,.177.008.265.012v1.616H20.828a2.491,2.491,0,0,0-2.588,2.61V90a2.519,2.519,0,0,0,2.7,2.689h6.825v1.623c-.067,0-.111.009-.156.009H20.747a1.238,1.238,0,0,1-.265-.034l-4.889-1.1a.273.273,0,0,1-.24-.238,2.442,2.442,0,1,0-.392,1.972.292.292,0,0,1,.354-.135c1.214.282,2.427.563,3.645.829a9.8,9.8,0,0,0,1.856.32c2.21.039,4.431.016,6.647.016h.321v3a.822.822,0,0,1-.919.919H4.155a4.482,4.482,0,0,1-1.018-.118A4,4,0,0,1,.917,98.34a2.482,2.482,0,0,0,.248-.192l5.2-5.2a1.165,1.165,0,0,0,.367-.892c-.01-1.935,0-3.873-.009-5.806a.338.338,0,0,1,.224-.364,2.343,2.343,0,0,0,1.407-2.023,2.426,2.426,0,0,0-2.016-2.593,2.453,2.453,0,0,0-2.746,1.66A2.427,2.427,0,0,0,4.918,85.89a.276.276,0,0,1,.187.3q0,2.74-.009,5.481a.585.585,0,0,1-.153.372Q2.652,94.36.343,96.657c-.053.053-.114.1-.193.164l-.15-.6" transform="translate(0 -74.88)" fill="gray" />
                    <path id="Path_22379" data-name="Path 22379" d="M4.263,2.682a8.277,8.277,0,0,1,.442-.825A3.944,3.944,0,0,1,8.1,0Q17.909-.01,27.715,0a.8.8,0,0,1,.865.835c.011.6,0,1.206,0,1.847Z" transform="translate(-4.031 0.005)" fill="gray" />
                    <path id="Path_22380" data-name="Path 22380" d="M373.922,208.017V212.9h-6.861a.908.908,0,0,1-1.045-1.043v-2.847a.884.884,0,0,1,.979-.994h6.932Zm-5.445,1.629a.807.807,0,1,0,.568.246.807.807,0,0,0-.568-.246" transform="translate(-346.15 -196.725)" fill="gray" />
                    <path id="Path_22381" data-name="Path 22381" d="M224.878,187.05a.808.808,0,1,1-.578.222.808.808,0,0,1,.578-.222" transform="translate(-211.89 -176.896)" fill="gray" />
                    <path id="Path_22382" data-name="Path 22382" d="M224.868,328.05a.807.807,0,1,1-.819.8.816.816,0,0,1,.819-.8" transform="translate(-211.89 -310.243)" fill="gray" />
                    <path id="Path_22383" data-name="Path 22383" d="M94.854,147.046a.808.808,0,1,1-.578.226.808.808,0,0,1,.578-.226" transform="translate(-88.922 -139.062)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h3 className="card-title">Secure crypto wallet integration</h3>
              </div> </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SecurityofOur  