import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100">

      <Container>
        <div className="text-center">
          
          <h2 className="heading-h2"><span className="heading-h3 bluecolor">Frequently </span>
             Asked Questions</h2>
        </div>

        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">

                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" variant="link" eventKey="0">
                How to build an NFT Marketplace like Foundation?
                </Accordion.Toggle>
                 
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">By using our first-class Foundation clone script, you can create and deploy the best NFT Marketplace like foundation instantly with spectacular features.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                How will I make money after launching an NFT Marketplace using the Foundation clone script?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">Whenever transactions happen using your NFT Marketplace, a small portion of the transaction will be deposited into your crypto wallet. The more transactions take place, the more money you make. It is important that once you have built your NFT Marketplace using the Foundation clone script, you need to encourage the digital art creators and the users to carry out as many transactions as possible.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                What are the commercial benefits of building an NFT Marketplace using the Foundation clone script?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">First of all, our premium Foundation clone script is affordable. But that’s not the only reason why you should go for our Foundation clone script. You can instantly launch your NFT Marketplace. You can generate a higher ROI and digital collectibles can be easily minted into non-fungible tokens. Besides, you can customize the platform in a hassle-free way.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                How much does it cost to develop an NFT Marketplace using the Foundation clone script?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">The NFT Marketplace development cost will be based on the scale of the project and complexity. So the cost of a foundation clone script will vary from project to project based on some essential attributes. 
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card mb-0' : 'panel-wrap card mb-0'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle" variant="link" eventKey="4">
                What are the features of white-label NFT Marketplace software?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">Our script is pre-loaded with mind blowing features such as different asset categories, performance monitoring, analytics dashboard, decentralized architecture, time constrained trade, intuitive admin panel, auction process, SSL protocol, multi-lingual support, referral program, and more
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection