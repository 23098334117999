import React from 'react'
import Slider from "react-slick";
import ButtonStartfree from '../ButtonComponent/ButtonStartfree'



class Banner extends React.Component {

  render() {

    const settings = {
      dots: false,
      infinite: true,
      speed: 2500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1000
    };

    return (
      <section className="banner">
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 col-sm-12 col-12 left-banner">
              <div className="mw600">
                <h1 className="main-heading"><span className="bluecolor">Foundation</span> Clone Script</h1>
                <p className="sub-heading">Create and launch an ultra-modern NFT auction marketplace with the latest features by using our supreme Foundation clone script. </p>
                <ul>
                  <li className="pharagraph">Your users can create NFTs over the Ethereum blockchain network.
                  </li>
                  <li className="pharagraph">Entirely community-driven operations.
                  </li>
                  <li className="pharagraph">Lifetime user performance tracking.
                  </li>
                </ul>
                <div className="banner-btn">
                  <ButtonStartfree />
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-12 right-banner">
            <div className="screen">
                <img width="806px" height="547px" src="https://coinsclone.mo.cloudinary.net/images/binance-new/laptop-binance.png" alt="foundation clone laptop front view" />
                <div className="outder">
                  <Slider {...settings}>
                    <img width="647px" height="403px" src="https://coinsclone.mo.cloudinary.net/images/foundation-new/white-label-foundation-clone-script-software.png" alt="white label foundation clone script software" />
                    <img width="647px" height="403px" src="https://coinsclone.mo.cloudinary.net/images/foundation-new/foundation-clone-script.png" alt="foundation clone script" />
                    <img width="647px" height="403px" src="https://coinsclone.mo.cloudinary.net/images/foundation-new/foundation-clone.png" alt="foundation-clone" />
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Banner