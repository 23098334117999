import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'

class WhyShould extends React.Component {


  render() {

    return (
      <section className="whyshould pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              
              <h2 className="heading-h2"><span className="heading-h3"><span className='bluecolor'>Why should</span> you Create an </span>
                NFT Marketplace using Foundation Clone Software?
              </h2>
              <p className="pharagraph head">
              </p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 text-center" >
              <img width="571px" height="584px" src="https://coinsclone.mo.cloudinary.net/images/foundation-new/why-should-you-create.png" alt="foundation clone script software" />
            </div>
            <div className="col-lg-6 col-md-12">
              <ul>
                <li className="pharagraph" >Your users can organize instant NFT auctions which help to attract all NFT enthusiasts. 
                </li>
                <li className="pharagraph" >The premium Foundation clone script has the NFT minting options which allows the creators to immediately covert digital collectibles into astounding non-fungible tokens.
                </li>
                <li className="pharagraph" >High customization scope.
                </li>
                <li className="pharagraph" >This clone script is developed and runs on the most trusted blockchain network - Ethereum.
                </li>
                <li className="pharagraph" >A modern and cost-effective solution to begin an NFT Marketplace business.
                </li>
                <li className="pharagraph" >Generate high ROI by enabling booming revenue-generating modules.</li>
                <li className="pharagraph" >No need for any technical expertise.
                </li>
                <li className="pharagraph" >Auctioning, bidding, and other major functions can be controlled by the admin.
                </li>
                <li className="pharagraph" >Comes with a cutting-edge admin and user dashboard with astonishing UI/UX.
                </li>
                <li className="pharagraph" >The Foundation clone is designed with impressive real-time performance tracking functionality.
                </li>
              </ul>

              <div className="banner-btn mt-4">
                <ButtonComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyShould