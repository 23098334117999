import React, { Component } from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


class Addon extends Component {


  // Tab
  openTabSection = (evt, tabNmae) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_item");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabNmae).style.display = "block";
    document.getElementById(tabNmae).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

            <h2 className="heading-h2"><span className="heading-h4"><span className="bluecolor">Add on Modules</span> of our </span>
              Foundation Clone Script Software</h2>
          </div>
          <div className="row">
            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role="presentation">
                <li role='presentation' onKeyDown={this.openTabSection} className="current" onClick={(e) => this.openTabSection(e, 'tabs1')}>
                  Referral Program
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs2')} >
                  Multi-lingual Support
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs3')} >
                  Day/Night Mode
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs4')} >
                  Multiple Payment Options
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs5')} >
                  Site API Documentation
                </li>

              </ul>
              <div className="tab-content">
                <div id="tabs1" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h3 className="heading-h4">Referral Program
                      </h3>
                      <p className="pharagraph">Refer your friends or surroundings to collect free rewards. Using this feature, it’s not only about getting rewards but also it promotes the site to grab more users.
                      </p>
                      <div className="text-left mt-4">
                        <ButtonComponent />
                      </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="430px" height="287px" src="https://coinsclone.mo.cloudinary.net/images/foundation-new/referral-program.png" alt="Referral Program" />
                    </div>
                  </div>
                </div>
                <div id="tabs2" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h3 className="heading-h4">Multi-lingual Support
                      </h3>
                      <p className="pharagraph">Attract global users by having multiple language options so that users can switch to their native language and understand the system better.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="286px" height="275px" src="https://coinsclone.mo.cloudinary.net/images/foundation-new/multi-lingual-support.png" alt="Multi-lingual Support" />
                    </div>
                  </div>
                </div>
                <div id="tabs3" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h3 className="heading-h4">Day/Night Mode
                      </h3>
                      <p className="pharagraph">One of the attractive features that makes the users more attentive is dark & light mode. Let the users access the system at their convenience by choosing in which mode they want to operate.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="500px" height="295px" src="https://coinsclone.mo.cloudinary.net/images/foundation-new/night-mode.png" alt="Day/Night Mode" />
                    </div>
                  </div>
                </div>
                <div id="tabs4" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h3 className="heading-h4">Multiple Payment Options</h3>
                      <p className="pharagraph">Users can not only bid or buy NFTs using crypto payments, but also they can do that using fiat payments that can be done through credit/debit cards, payment gateways, etc..
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="342px" height="296px" src="https://coinsclone.mo.cloudinary.net/images/foundation-new/multiple-payment-options.png" alt="Multiple Payment Options" />
                    </div>
                  </div>
                </div>
                <div id="tabs5" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h3 className="heading-h4">Site API Documentation
                      </h3>
                      <p className="pharagraph">Using the rest API we provide , you can connect your NFT Marketplace with other platforms and pull a set of datas through parameters that includes sales, bids, transfers and more.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="487px" height="324px" src="https://coinsclone.mo.cloudinary.net/images/foundation-new/site-api-documentation.png" alt="Site API Documentation" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Addon