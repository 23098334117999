import React, { Component } from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


class CoreFeatures extends Component {

  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">

        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

            <h2 className="heading-h2"><span className="heading-h4"><span className="bluecolor">Core Features</span> of </span>
              Our Foundation Clone Script</h2>
          </div>
          <div className="row">

            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role='presentation'>
                <li className="current" role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab1')}>
                  Decentralized Architecture
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab2')} >
                  Various Digital Collectibles
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab3')} >
                  Time Constrained Trade
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab4')} >
                  Intuitive Admin Panel
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab5')} >
                  Bid and Buy
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab6')} >
                  Highly Secured 
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab7')} >
                  Auction Process
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab8')} >
                  Alternative Blockchain Network
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab9')} >
                  Crypto Wallets 
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab10')} >
                  Trending Collectibles
                </li>
              </ul>

              <div className="tab-content">
                <div id="tab1" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Decentralized Architecture
                        </h3>
                        <p className="pharagraph">Elimination of the traditional trade system like register, login & document verification makes our Foundation clone script to stay anonymous and thus save your time by just connecting the wallets and start minting the NFTs.
                        </p>
                        <div className="text-left mt-4">
                          <ButtonComponent />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="263px" height="278px" src="https://coinsclone.mo.cloudinary.net/images/foundation-new/decentralized-architecture-decentralized-architecture.png" alt="Decentralized Architecture" />
                    </div>
                  </div>
                </div>
                <div id="tab2" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Various Digital Collectibles
                        </h3>
                        <p className="pharagraph">Not just the artworks & gaming industry, our Foundation clone script also supports housing & real estate, energy industry, healthcare, fashion, insurance, sports, etc.. 
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="432px" height="285px" src="https://coinsclone.mo.cloudinary.net/images/foundation-new/various-digital-collectibles.png" alt="Various Digital Collectibles" />
                    </div>
                  </div>
                </div>
                <div id="tab3" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Time Constrained Trade

                        </h3>
                        <p className="pharagraph">In our Foundation clone script, users are able to set a specific time in which their auctions should stay active for the other users. By using this feature, the users can trade any time on their availability.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="372px" height="278px" src="https://coinsclone.mo.cloudinary.net/images/foundation-new/time-constrained-trade.png" alt="Time Constrained Trade" />
                    </div>
                  </div>
                </div>
                <div id="tab4" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Intuitive Admin Panel
                        </h3>
                        <p className="pharagraph">Our Foundation clone script has a user-friendly admin dashboard where you can manage all the user side functions, commissions, CMS and other features of your marketplace website.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="410px" height="256px" src="https://coinsclone.mo.cloudinary.net/images/foundation-new/intuitive-admin-panel.png" alt="Intuitive Admin Panel" />
                    </div>
                  </div>
                </div>
                <div id="tab5" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Bid and Buy
                        </h3>
                        <p className="pharagraph">Our Foundation clone script is a ready to launch smart contract audited NFT Marketplace software that executes effective buying, selling, and auctioning of digital Collectibles
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img className="mw380" width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/foundation/bid-and-buy.png" alt="Bid and Buy" />
                    </div>
                  </div>
                </div>
                <div id="tab6" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Highly Secured
                        </h3>
                        <p className="pharagraph">Security is a major concern when it comes to financial handling platforms especially for digital funds. To ensure this, our Foundation clone script comes with high end security features configured. 
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="294px" height="278px" src="https://coinsclone.mo.cloudinary.net/images/foundation-new/highly-secured.png" alt="Highly Secured" />
                    </div>
                  </div>
                </div>
                <div id="tab7" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Auction Process
                        </h3>
                        <p className="pharagraph">Buyers can place bids on how much they are willing to pay for the NFT as long as they are above the minimum price. Then the NFT is sold to the highest bidder at the end of the time period in the several auctions posted by the creators.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img className="mw315" width="333px" height="270px" src="https://coinsclone.mo.cloudinary.net/images/foundation-new/auction-process.png" alt="Auction Process" />
                    </div>
                  </div>
                </div>
                <div id="tab8" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Alternative Blockchain Network
                        </h3>
                        <p className="pharagraph">Though Foundation is entirely based on the Ethereum network, our clone script is capable of supporting dynamic networks like tron, polygon, polkadot etc..
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="253px" height="258px" src="https://coinsclone.mo.cloudinary.net/images/foundation-new/alternative-blockchain-network.png" alt="Alternative Blockchain Network" />
                    </div>
                  </div>
                </div>
                <div id="tab9" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Crypto Wallets
                        </h3>
                        <p className="pharagraph">Our Foundation clone script comes with secured wallet connect options so users can choose their desired digital wallet to deposit the assets. </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img className="mw380" width="280px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/foundation-new/crypto-wallets.png" alt="Crypto Wallets" />
                    </div>
                  </div>
                </div>
                <div id="tab10" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Trending Collectibles
                        </h3>
                        <p className="pharagraph">Get updated and stay connected with the market by viewing the trending auctions. In the trending section users can see the current bid price, end date and more details.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="430px" height="283px" src="https://coinsclone.mo.cloudinary.net/images/foundation-new/trending-collectibles.png" alt="Trending Collectibles" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CoreFeatures