import React,{useState, useEffect} from 'react'

import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Banner from "../components/Foundation/Banner"
import Whatis from "../components/Foundation/Whatis"
import QuickviewSection from "../components/Foundation/Quickview"
import HowOur from "../components/Foundation/HowOur"
import TopFeatures from "../components/Foundation/TopFeatures"
import CoreFeatures from "../components/Foundation/CoreFeatures"
import SecurityofOur from "../components/Foundation/SecurityofOur"
import TechStack from "../components/Foundation/TechStack"
import FaqSection from "../components/Foundation/FaqSection"
import WhyShould from "../components/Foundation/WhyShould"
import DevApproach from "../components/Foundation/DevApproach"
import WhyChoose from "../components/Foundation/WhyChoose"
import Addon from "../components/Foundation/Addon"
import OurAndroid from "../components/Foundation/OurAndroid"
import Testimonial from "../components/Foundation/Testimonial"
import UserFriendly from "../components/Cryptoexchange/UserFriendly"





const Clone = () => {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const updateMedia = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", updateMedia);
    updateMedia(); // Check initial size

    return () => window.removeEventListener("resize", updateMedia);
  }, []);
 return(
  <section className="landing clone">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <title>Foundation Clone Script to Start NFT Auction Marketplace</title>
        <meta name="description" content="Foundation Clone Script is a tailor made NFT Marketplace script that is used to start an NFT Auction Marketplace similar to Foundation instantly." />
        <meta property="keywords" content="White Label Foundation Clone Script, Foundation Clone App Development, Create NFT Marketplace like Foundation, White Label Foundation Clone Software" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Foundation Clone Script to Start NFT Auction Marketplace" />
        <meta property="og:description" content="Foundation Clone Script is a tailor made NFT Marketplace script that is used to start an NFT Auction Marketplace similar to Foundation instantly." />
        <meta property="og:url" content="https://www.coinsclone.com/foundation-clone-script/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/foundation-clone-script.png" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Foundation Clone Script is a tailor made NFT Marketplace script that is used to start an NFT Auction Marketplace similar to Foundation instantly." />
        <meta name="twitter:title" content="Foundation Clone Script to Start NFT Auction Marketplace" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/foundation-clone-script.png" />
        <link rel="canonical" href="https://www.coinsclone.com/foundation-clone-script/" />
      </Helmet>
      <Banner />
      <div className="breadcrumb">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | Foundation Clone Script </span>
        </div>
      </div>
      <Whatis />
      {isDesktop && (
          <QuickviewSection />
          )}
      <HowOur />
      <WhyShould />
      <TopFeatures />
      <CoreFeatures />
      <SecurityofOur />
      <Addon />
      <OurAndroid />
      <WhyChoose />
      <DevApproach />
      <Testimonial /> 
      <TechStack />
      <FaqSection />
      <UserFriendly />
    </Layout>
  </section>
)
}
export default Clone
